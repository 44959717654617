









































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import store from '../store'
import Offer from '../models/offer'
import Error from '../models/error'
import Inquiry from '../models/inquiry'
import Header from '@/components/Header.vue'
import PageHero from '@/components/PageHero.vue'
import TopOffers from '@/components/TopOffers.vue'
import MediaEmbed from '@/components/MediaEmbed.vue'
import Offers from '@/components/Offers.vue'
import Contact from '@/components/Contact.vue'
import LegalInfo from '@/components/LegalInfo.vue'
import Newsletter from '@/components/Newsletter.vue'
import InquiryForm from '@/components/InquiryForm.vue'

import { page } from 'vue-analytics'

@Component({
    components: {
        Header,
        PageHero,
        TopOffers,
        MediaEmbed,
        Offers,
        Contact,
        LegalInfo,
        Newsletter,
        InquiryForm,
    },
})
export default class App extends Vue {
    private logo = 'netfonds-logo.png';
    private error: Error;
    private modalOpen: any = {
        newsletter: false,
        inquiry: false,
    };

    private getOffers(): Promise<any> {
        return axios.get(
            `https://api.bdrops.space/bundk/api/offers/page/${this.page.id}`,
        )
    }

    private getTopOffers(): Promise<any> {
        return axios.get(
            `https://api.bdrops.space/bundk/api/topoffers/page/${this.page.id}`,
        )
    }

    private get offers(): any {
        return store.state.offers
    }

    private get page(): any {
        return store.state.page
    }

    private get activeBMWOffer(): number {
        return store.getters.activeBMWOffer
    }

    private get activeMINIOffer(): number {
        return store.getters.activeMINIOffer
    }

    private get activeElectroOffer(): number {
        return store.getters.activeElectroOffer
    }

    private created(): void {
        store.commit('setInquiry', {
            pageId: this.page.id,
        })

        this.$on('openModal', (type: string) => {
            this.modalOpen[type] = true
        })

        window.EventBus.$on('closeModal', (type: string) => {
            this.modalOpen[type] = false
        })
    }

    private mounted(): void {
        this.getOffers().then(
            (result) => {
                store.commit(
                    'setOffers',
                    result.data.filter((item: Offer) => item.category === 'BMW'),
                )
                store.commit(
                    'setOffers',
                    result.data.filter((item: Offer) => item.category === 'MINI'),
                )
                store.commit(
                    'setOffers',
                    result.data.filter((item: Offer) => item.category === 'Electro'),
                )
            },
            (error) => {
                this.error.message = error.response
            },
        )
        this.getTopOffers().then(
            (result) => {
                store.commit('setTopOffers', result.data)
            },
            (error) => {
                this.error.message = error.response
            },
        )
        this.track()
    }

    private track(): void {
        page('/')
    }
}
