












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MediaEmbed extends Vue {
  @Prop({ default: '16by9' }) private aspectRatio: string;
  @Prop({ default: '' }) private url: string;
}
