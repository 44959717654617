









































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import store from '../store';
import Page from '../models/page';
import axios from 'axios';
import { event } from 'vue-analytics';

@Component({
    components: {
        Modal,
    },
})
export default class Newsletter extends Vue {
    @Prop({ default: false }) private open!: boolean;
    private get page(): Page {
        return store.state.page;
    }

    private subscriptionData: any = {
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        page: this.page.id,
    };

    private loading: boolean = false;
    private error: boolean = false;
    private success: boolean = false;
    private message: any = null;
    private timeout: number | null = null;

    private sendSubscription(data: any): Promise<any> {
        return axios.post('https://api.bdrops.space/bundk/api/newsletter', data);
    }

    private subscribe(): void {
        this.loading = true;
        this.sendSubscription(this.subscriptionData).then((response) => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.message = `Anmeldung erfolgreich!
Bitte sehen Sie jetzt in Ihr E-Mail Postfach und bestätigen Sie Ihre Anmeldung.`;
            event('Newsletter', 'subscription');
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.success = false;
                this.message = null;
                window.EventBus.$emit('closeModal', 'newsletter');
            }, 3000);
        }, (error) => {
            this.loading = false;
            this.success = false;
            this.error = true;
            this.message = `Leider ist bei Ihrer Anmeldung ein Fehler aufgetreten.
Bitte versuchen Sie es später noch einmal!`;
            event('Newsletter', 'error');
            if (error.response && error.response.status === 400) {
                this.message = `Sie haben sich bereits für unseren Newsletter angemeldet.`;
            }
        });
    }
}
