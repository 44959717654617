








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { event } from 'vue-analytics'

@Component
export default class Contact extends Vue {
  @Prop() private contact: Record<string, unknown>;

  private openModal(type: string): void {
    this.$parent.$emit('openModal', type)
    if ('newsletter' === type) {
      event('Newsletter', 'modal')
    }
  }
}
