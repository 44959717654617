



































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import store from '../store';
import Offer from '../models/offer';
import Inquiry from '../models/inquiry';
import Page from '../models/page';
import { event } from 'vue-analytics';

@Component
export default class Offers extends Vue {
    @Prop({ required: true, default: '' }) private logo: string;
    @Prop({ required: false, default: 'bmw' }) private mode: string;
    @Prop({ required: true, default: [] }) private offers: Offer[];
    @Prop({ required: true, default: 0 }) private activeOffer: number;

    private activeIdx: number = 0;
    private timeout: number | null = null;

    private get inquiry(): Inquiry {
        return store.state.inquiry;
    }

    private get page(): Page {
        return store.state.page;
    }

    private formInquiry = {
        name: '',
        phone: '',
        email: '',
        subject: '',
        text: '',
    };

    @Watch('activeOffer')
    private onIndexChanged(val: number, oldval: number) {
        this.activeIdx = val;
        this.formInquiry.subject =
            `${this.page.name} Angebotsanfrage: ${this.offers[val].category} ${this.offers[val].title}`;
    }

    @Watch('activeIdx')
    private onActiveIndexChanged(val: number) {
        this.formInquiry.subject =
            `${this.page.name} Angebotsanfrage: ${this.offers[val].category} ${this.offers[val].title}`;
    }

    private setActiveOffer(idx: number): void {
        this.activeIdx = idx;
    }

    private prevOffer(): void {
        if (this.activeIdx > 0) {
            this.activeIdx--;
        }
    }

    private nextOffer(): void {
        if (this.activeIdx < this.offers.length) {
            this.activeIdx++;
        }
    }

    private openModal(type: string): void {
        this.$parent.$emit('openModal', type);
        if ('newsletter' === type) {
            event('Newsletter', 'modal');
        }
    }

    private openInquiry(): void {
        store.commit('setInquiry', this.formInquiry);
        this.openModal('inquiry');
        event('Offer', 'modal', this.offers[this.activeIdx].title);
    }

    private mounted(): void {
        if (this.timeout) {
            window.clearTimeout(this.timeout)
        }
        this.timeout = window.setTimeout(() => {
            if (this.offers.length > 0) {
                this.formInquiry.subject =
                    `${this.page.name} Angebotsanfrage: ${this.offers[this.activeOffer > 0 ? this.activeOffer : 0].title}`
            }
            this.activeIdx = this.activeOffer > 0 ? this.activeOffer : 0
        }, 1000)
    }
}
