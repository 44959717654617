



































import { Component, Vue } from 'vue-property-decorator';
import store from '../store';
import Page from '../models/page';

@Component
export default class LegalInfo extends Vue {
    private get page(): Page {
        return store.state.page;
    }

    private date: Date = new Date();

    private get month(): number {
        return this.date.getUTCMonth() + 1;
    }

    private get year(): number {
        return this.date.getUTCFullYear();
    }
}
