














































































































import { Vue, Prop, Component } from 'vue-property-decorator'
import { event } from 'vue-analytics'
import store from '../store'

@Component
export default class PageHero extends Vue {
    @Prop() private image!: string;
    @Prop() private page!: Record<string, unknown>;

    private get electroCount(): number {
        return store.state.offers.Electro.length
    }

    private openModal(type: string): void {
        this.$parent.$emit('openModal', type)
        if ('newsletter' === type) {
            event('Newsletter', 'modal')
        }
    }
}
