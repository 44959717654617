











































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios, { AxiosPromise } from 'axios'
import store from '../store'
import Inquiry from '../models/inquiry'
import Modal from '@/components/Modal.vue'
import { event } from 'vue-analytics'

@Component({
    components: {
        Modal,
    },
})

export default class InquiryForm extends Vue {
    @Prop({ default: false }) private open!: boolean;

    private get inquiry(): Inquiry {
        return store.state.inquiry
    }

    private formInquiry: Record<string, string | null> = {
        email: null,
        name: null,
        phone: null,
        text: null,
        mileage: null,
        duration: null,
        configuratorLink: null,
    };

    private success = false;
    private error = false;
    private loading = false;
    private message: string | null = null;
    private timeout: number | null = null;

    private postInquiry(data: Record<string, unknown>): AxiosPromise<Record<string, unknown>> {
        return axios.post('https://api.bdrops.space/bundk/api/inquiry', data)
    }

    private submitInquiry(): void {
        this.loading = true
        this.success = false
        this.error = false
        this.message = null
        const inquiry = {}
        Object.assign(inquiry, this.inquiry)
        Object.assign(inquiry, this.formInquiry)
        this.postInquiry(inquiry).then(() => {
            this.loading = false
            this.success = true
            this.error = false
            this.message = `Vielen Dank! Wir haben Ihre Anfrage erhalten
und werden sie in Kürze bearbeiten.`
            event('Offer', 'inquiry', this.inquiry.subject)
            if (this.timeout) {
                window.clearTimeout(this.timeout)
            }
            this.timeout = window.setTimeout(() => {
                this.success = false
                this.message = null
                this.formInquiry.text = null
                window.EventBus.$emit('closeModal', 'inquiry')
            }, 3000)
        }, () => {
            this.loading = false
            this.error = true
            this.success = false
            this.message = `Leider ist bei der Übermittlung Ihrer Anfrage etwas schiefgegangen.
Versuchen Sie es bitte später noch einmal.`
        })
    }
}
