































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TopOffer from '../models/topoffer';
import Page from '../models/page';
import store from '../store';

@Component
export default class TopOffers extends Vue {
    @Prop() private logo: string | null;

    private get topOffers(): TopOffer[] {
        return store.state.topOffers;
    }

    private get page(): Page {
        return store.state.page;
    }

    private openModal(type: string): void {
        this.$parent.$emit('openModal', type);
    }
}
